import { compiledBlock as _compiledBlock } from "million/react";
import React, { useState } from "react";
import Footer from "../../components/Footer/Footer";
import ProductDetailAcne from "../../components/ProductDetail/ProductDetailAcne";
import BeforeAfterReviews from "../../components/BeforeAfterReviews/BeforeAfterReviews";
import DescriptionPercentage from "../../components/DescrptionPercentage/DescriptionPercentage";
import BenefitsandVideo from "../../components/BenefitsAndvideo/BenefitsandVideo";
import CalendlyFooter from "../../components/CalendlyFooter/CalendlyFooter";
import ImageDescription from "../../components/ImageDescription/ImageDescription";
import Reviews from "../../components/Reviews/Reviews";
import HeaderProduct from "../../components/Header/HeaderProduct";
import QuestionsAnswersEnd from "../../components/QuestionsAnswersHome/QuestionsAnswersEnd";
import { block } from 'million/react';
const LandingPageAcne_1 = _compiledBlock(_props => <div>
      {_props.v0}
      <div className="px-4 lg:px-10 xl:px-20 bg-white space-y-6 lg:space-y-20 py-4 overflow-hidden">
        {_props.v1}
        {_props.v2}
        {_props.v3}
        {_props.v4}
        {_props.v5}
        {_props.v6}
        {_props.v7}
      </div>
      {_props.v8}
      {_props.v9}
    </div>, {
  name: "LandingPageAcne_1",
  portals: ["v0", "v1", "v2", "v3", "v4", "v5", "v6", "v7", "v8", "v9"]
});
const LandingPageAcne = () => {
  const [homeVariant, setHomeVariant] = useState(2);
  const handleHomeVariant = variant => {
    setHomeVariant(variant);
  };
  const acne = {
    title: "Acne Detox Liquid Niacinamide + Squalane Vit A",
    subtitle: "If you're tired of acne products that don't deliver the results you expect, our Acne Detox is for you!",
    items: ["Eliminates breakouts and improves appearance, boosting your confidence.", " Calms redness, so you can socialize without worrying about your skin.", "Achieve smooth and clear skin.", "Clinically proven ingredients that enhance your skin."],
    questions: ["How does it work?"]
  };
  return /*@million jsx-skip*/<LandingPageAcne_1 v0={<HeaderProduct />} v1={<ProductDetailAcne homeVariant={homeVariant} handleVariantDetail={handleHomeVariant} />} v2={<BenefitsandVideo acne={"Acne"} />} v3={<DescriptionPercentage />} v4={<BeforeAfterReviews acne={"acne"} />} v5={<ImageDescription />} v6={<Reviews acne={"acne"} />} v7={<QuestionsAnswersEnd acne={"acne"} />} v8={<CalendlyFooter handleVariantFooter={handleHomeVariant} homeVariant={homeVariant} />} v9={<Footer />} />;
};
export default LandingPageAcne;