import { compiledBlock as _compiledBlock } from "million/react";
import { block as _block } from "million/react";
import React, { useState } from "react";
import { logo } from "../../assets/index";
const Header_1 = _compiledBlock(_props => <div>
      <div className={`z-40 top-0 right-0 w-screen bg-slate-600 mx-auto flex items-center py-6 justify-center lg:hidden`} style={_props.v0}>
        <div className="flex items-center ">
          <a href="/">
            <img className="w-[105px] " src={_props.v1} alt="Logo" />
          </a>
        </div>
      </div>
      <div className="hidden lg:flex justify-center items-center  w-screen h-32 bg-slate-600 shadow-lg lg:px-20 xl:px-36 py-2 z-40">
        <div className="flex justify-center items-center h-full">
          <img className="h-full" src={_props.v2} alt="logo-galindosa" />
        </div>
       
      </div>
    </div>, {
  name: "Header_1"
});
const Header = ({}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };
  return /*@million jsx-skip*/<Header_1 v0={{
    maxWidth: "1150px",
    height: "100px"
  }} v1={logo} v2={logo} />;
};
export default Header;