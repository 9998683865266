import { compiledBlock as _compiledBlock } from "million/react";
const VideoPlayer_1 = _compiledBlock(_props => <div className="flex justify-start items-center w-[86%] lg:w-[67%] ">
      <div className=" rounded-xl relative">
      {_props.v0}
        <div className="absolute bottom-4 left-2 lg:left-4 flex items-center space-x-2">
          <div className="rounded-full bg-white w-[16px] lg:w-[28px] h-[16px] lg:h-[28px] flex justify-center items-center overflow-hidden">
            <img src={_props.v1} alt="" className="w-[10px] lg:w-[20px]" />
          </div>
          <h1 className="font-sans-600 text-sm lg:text-lg text-white">IF Beauty</h1>
        </div>
      </div>
    </div>, {
  name: "VideoPlayer_1",
  portals: ["v0"]
});
const VideoPlayer = function VideoPlayer({
  url
}) {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const handleClick = event => {
    event.preventDefault();
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };
  return /*@million jsx-skip*/<VideoPlayer_1 v0={<video ref={videoRef} preload="none" autoPlay="false" loop muted playsInline loading="lazy">
          <source src={url} type="video/mp4" />
          Tu navegador no soporta el elemento de video.
        </video>} v1={logo_blanco} />;
};
import { block as _block } from "million/react";
import React, { useRef, useState } from "react";
import { logo_blanco } from "../../assets";
import { FaRegCirclePlay } from "react-icons/fa6";
export default VideoPlayer;