import { compiledBlock as _compiledBlock } from "million/react";
import { block as _block } from "million/react";
import React, { useState } from 'react';
const Registro3_1 = _compiledBlock(_props => <div className="App p-4 space-y-4 w-[400px]">
      <div className="flex flex-wrap justify-between">
        <h1 className="tracking-widest text-xl">SIGN UP TO RECEIVE</h1>
        <div className="bg-gray-700 px-2 text-white cursor-pointer" onClick={_props.v0}>
          x
        </div>
      </div>
      <h1 className="text-left">
      If you would like to receive a complete anti-aging skincare routine, we can send it to you via email.
      </h1>
      <div>
        <form className="flex flex-wrap space-y-2 " onSubmit={_props.v1}>
          <input className="w-full border-gray-700 border-[1px] py-2 px-2 focus:outline-none focus:border-gray-700" placeholder="Your Name" name="Name" type="text" value={_props.v2} onChange={_props.v3} />
          <input className="w-[80%] border-gray-700 border-[1px] py-2 px-2 focus:outline-none focus:border-gray-700" placeholder="Your Email" name="Email" type="text" value={_props.v4} onChange={_props.v5} />
          <input type="submit" className="submit-btn w-[20%] bg-gray-700 text-white p-2 cursor-pointer" value="Submit" />
        </form>
      </div>
      <div className="w-full flex justify-center px-12">
        <img className="lg:w-2/3" src="https://ifbeauty.com.au/cdn/shop/files/Untitled-2_5d5334ce-3bfb-45eb-a2c5-d56c3e19c8ad_540x.jpg?v=1629700430" alt="" />
      </div>
    </div>, {
  name: "Registro3_1"
});
const Registro3 = ({
  handleShowRegistro,
  actualizarEstado
}) => {
  const [formData, setFormData] = useState({
    Name: '',
    Email: ''
  });
  const handleChange = e => {
    const {
      name,
      value
    } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
  const handleSubmit = e => {
    e.preventDefault();
    // Verificar si se han proporcionado tanto el nombre como el correo electrónico
    if (formData.Name && formData.Email) {
      // Llamar a la función Submit solo si se han proporcionado ambos datos
      Submit();
    } else {
      // Mostrar un mensaje de error o realizar alguna acción adicional si falta algún dato
      alert('Complete the fields or quit the form.');
    }
  };
  const Submit = () => {
    const formDatab = new FormData();
    for (const key in formData) {
      formDatab.append(key, formData[key]);
    }
    fetch("https://script.google.com/macros/s/AKfycbwVvIFlBkWy5lq9jfAyzzLfBbPVnyebOFGQ-e31nGecLQG3ywWur8zl5d_ksp3PJ16S/exec", {
      method: "POST",
      body: formDatab,
      mode: "no-cors"
    }).then(res => res.json()).then(data => {
      console.log(data);
    }).catch(error => {
      console.log(error);
    });

    // window.fbq("trackCustom", "FormSubmitted");
    actualizarEstado(true);
  };
  const handleClick = () => {
    handleShowRegistro();
  };
  return /*@million jsx-skip*/<Registro3_1 v0={handleClick} v1={handleSubmit} v2={formData.Name} v3={handleChange} v4={formData.Email} v5={handleChange} />;
};
export default Registro3;