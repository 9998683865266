import { compiledBlock as _compiledBlock } from "million/react";
import React, { useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import YoutubePlayer from "../../components/YoutubePlayer/YoutubePlayer";
import { block } from "million/react";
import { MdAdsClick } from "react-icons/md";
import { useHistory } from "react-router-dom";
const LandingPageVideo_1 = _compiledBlock(_props => <div>
      {_props.v0}
      <div className="px-2 lg:px-10 xl:px-20 bg-slate-600 space-y-0 lg:space-y-2 py-4 lg:pb-12">
        {_props.v1}
        <div>
          <h1 className="font-montserrat-500 text-md lg:text-lg text-gray-200 text-center pb-1">
          If You Want to Boost Your Collagen
          </h1>
        </div>
        <div style={_props.v2}>
          <button onClick={_props.v3} className="z-50 flex justify-center items-center max-w-[700px] bg-gradient-to-r from-[#40b4e5] to-[#09a3e4] text-white text-xl lg:text-2xl py-4 px-6 rounded-xl mb-2 mx-4 my-0 md:my-2 uppercase" style={_props.v4}>
            {_props.v5}
            Discover Our Product
          </button>
        </div>
      </div>
      {_props.v6}
    </div>, {
  name: "LandingPageVideo_1",
  portals: ["v0", "v1", "v5", "v6"]
});
const LandingPageVideo = () => {
  const history = useHistory();
  const [homeVariant, setHomeVariant] = useState(2);
  const handleHomeVariant = variant => {
    setHomeVariant(variant);
  };
  const handleClick = () => {
    history.push("/collagen");
  };
  return /*@million jsx-skip*/<LandingPageVideo_1 v0={<Header />} v1={<YoutubePlayer youtube_url="x4FsgRb7eEw" landingVideo={"LandingVideo"} />} v2={{
    display: "flex",
    justifyContent: "center"
  }} v3={handleClick} v4={{
    marginBottom: "3rem",
    justifyContent: "center"
  }} v5={<MdAdsClick />} v6={<Footer />} />;
};
export default LandingPageVideo;