import { compiledBlock as _compiledBlock } from "million/react";
import React from "react";
import { block } from 'million/react';
const StatementBar_1 = _compiledBlock(_props => <div className="p-2">
      <div className="flex items-start mb-2 space-x-1 text-left">
        <div className="text-4xl font-sans-500">{_props.v0}%</div>
        <div className="font-sans-300">{_props.v1}</div>
      </div>
      <div className="w-full bg-gray-200 rounded-full h-4 overflow-hidden">
        <div className="transition-all ease-out duration-1000 bg-gradient-to-r from-[#1c4cfc] to-[#a4b6fafb] h-full rounded-full" style={_props.v2}></div>
      </div>
    </div>, {
  name: "StatementBar_1",
  portals: ["v0", "v1"]
});
const StatementBar = ({
  statement,
  percentage
}) => {
  return /*@million jsx-skip*/<StatementBar_1 v0={percentage} v1={statement} v2={{
    width: `${percentage}%`
  }} />;
};
const StatementList_1 = _compiledBlock(_props2 => <div className="bg-gray-100 rounded-xl p-4 border-[1.5px] border-gray-300">
      {_props2.v0}
      {_props2.v1}
      {_props2.v2}
    </div>, {
  name: "StatementList_1",
  portals: ["v0", "v1", "v2"]
});
const StatementList = () => {
  return /*@million jsx-skip*/<StatementList_1 v0={<StatementBar statement="Presents fewer wrinkles." percentage={80} />} v1={<StatementBar statement="Achieved a clinically proven reduction in expression lines." percentage={97} />} v2={<StatementBar statement="Achieved a clinically proven increase in skin elasticity and firmness." percentage={90} />} />;
};
export default StatementList;