import { compiledBlock as _compiledBlock } from "million/react";
import React, { useState } from "react";
import ProductDetail from "../../components/ProductDetail/ProductDetail";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import BeforeAfterReviews from "../../components/BeforeAfterReviews/BeforeAfterReviews";
import DescriptionPercentage from "../../components/DescrptionPercentage/DescriptionPercentage";
import BenefitsandVideo from "../../components/BenefitsAndvideo/BenefitsandVideo";
import CalendlyFooter from "../../components/CalendlyFooter/CalendlyFooter";
import ImageDescription from "../../components/ImageDescription/ImageDescription";
import Reviews from "../../components/Reviews/Reviews";
import YoutubePlayer from "../../components/YoutubePlayer/YoutubePlayer";
import QuestionsAnswersEnd from "../../components/QuestionsAnswersHome/QuestionsAnswersEnd";
import { block } from 'million/react';
const LandingPage_1 = _compiledBlock(_props => <div>
      {_props.v0}
      <div className="px-2 lg:px-10 xl:px-20 bg-slate-600 space-y-6 lg:space-y-20 py-4 lg:pb-12">
        {_props.v1}
      </div>
      <div className="px-4 lg:px-10 xl:px-20 bg-white space-y-6 lg:space-y-20 py-4 overflow-hidden">
        {_props.v2}
        {_props.v3}
        {_props.v4}
        {_props.v5}
        {_props.v6}
        {_props.v7}
        {_props.v8}
      </div>
      {_props.v9}
      {_props.v10}
    </div>, {
  name: "LandingPage_1",
  portals: ["v0", "v1", "v2", "v3", "v4", "v5", "v6", "v7", "v8", "v9", "v10"]
});
const LandingPage = () => {
  const [homeVariant, setHomeVariant] = useState(2);
  const handleHomeVariant = variant => {
    setHomeVariant(variant);
  };
  return /*@million jsx-skip*/<LandingPage_1 v0={<Header />} v1={<YoutubePlayer youtube_url="x4FsgRb7eEw" />} v2={<ProductDetail homeVariant={homeVariant} handleVariantDetail={handleHomeVariant} />} v3={<BenefitsandVideo />} v4={<DescriptionPercentage />} v5={<BeforeAfterReviews />} v6={<ImageDescription />} v7={<Reviews />} v8={<QuestionsAnswersEnd />} v9={<CalendlyFooter handleVariantFooter={handleHomeVariant} homeVariant={homeVariant} />} v10={<Footer />} />;
};
export default LandingPage;