import { compiledBlock as _compiledBlock } from "million/react";
import { block as _block } from "million/react";
import React, { useState, useEffect } from "react";
import Stars from "./Stars";
import star from "../../assets/star.svg";
import { reviews, acne_reviews } from "./AllReviews";
const Reviews_1 = _compiledBlock(_props => <div>
      <div>
        <div className="w-full flex justify-center py-4">
          <h1 className="text-gray-700 text-4xl mx-2">4.8</h1>
          <div className="flex h-8">
            <img src={_props.v0} alt="star" />
            <img src={_props.v1} alt="star" />
            <img src={_props.v2} alt="star" />
            <img src={_props.v3} alt="star" />
            <img src={_props.v4} alt="star" />
          </div>
        </div>
        <div className="space-y-10">
          {_props.v5}
          {_props.v6}
        </div>
      </div>
    </div>, {
  name: "Reviews_1",
  portals: ["v5", "v6"]
});
const Reviews = ({
  acne
}) => {
  const [visibleReviews, setVisibleReviews] = useState(3);
  const maxVisibleReviews = 3; // Máximo número de reviews visibles
  const [fullReviews, setFullReviews] = useState("");
  useEffect(() => {
    if (acne) {
      setFullReviews(acne_reviews);
    } else {
      setFullReviews(reviews);
    }
  }, [acne_reviews, reviews]);
  const handleShowMore = () => {
    if (visibleReviews + 3 <= fullReviews.length) {
      setVisibleReviews(visibleReviews + 3);
    }
  };
  const handleShowLess = () => {
    if (visibleReviews > maxVisibleReviews) {
      setVisibleReviews(visibleReviews - 3);
    }
  };
  return /*@million jsx-skip*/<Reviews_1 v0={star} v1={star} v2={star} v3={star} v4={star} v5={fullReviews.length !== 0 ? fullReviews.slice(0, visibleReviews).map((review, index) => <div key={index} className="space-y-2 border-b-[1px] border-gray-400 py-2">
                  <div className="flex gap-x-2">
                    <h1 className="font-sans-600">{review.name}</h1>
                    <h1 className="text-green-600 font-sans-500">
                      Verified Buyer
                    </h1>
                  </div>
                  <div>
                    <Stars starsRate={review.starRate === "4" ? true : false} />
                  </div>
                  <div>
                    <h1 className="text-left text-lg font-sans-600">
                      {review.title}
                    </h1>
                  </div>
                  <div>
                    <h1 className="font-sans-400 text-gray-600 text-left">
                      {review.review}
                    </h1>
                  </div>
                </div>) : ""} v6={fullReviews.length !== 0 ? fullReviews.length > maxVisibleReviews && <div className="text-center">
              <button onClick={handleShowLess} className="text-blue-600 font-sans-600 underline">
                Show Less
              </button>
              {" | "}
              <button onClick={handleShowMore} className="text-blue-600 font-sans-600 underline">
                Show More
              </button>
            </div> : ""} />;
};
export default Reviews;