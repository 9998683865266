import { compiledBlock as _compiledBlock } from "million/react";
import React, { useState, useRef } from "react";
import StatementList from ".././ProgressBar/ProgressBar";
import Slider from "react-slick";
import SampleNextArrow from "../../utils/SampleNextArrow";
import SamplePrevArrow from "../../utils/SamplePrevArrow";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import VideoWithPreview from "./VideoPreview";
import { block } from 'million/react';
const JSX_settings_1 = _compiledBlock(_props => _props.v0, {
  name: "JSX_settings_1",
  portals: ["v0"]
});
const JSX_settings_2 = _compiledBlock(_props2 => _props2.v0, {
  name: "JSX_settings_2",
  portals: ["v0"]
});
const DescriptionPercentage_1 = _compiledBlock(_props3 => <div className="flex flex-wrap justify-center items-start h-auto lg:h-[550px] xl:space-x-0">
      <div className="w-full lg:w-[60%] flex flex-wrap justify-start items-start space-y-2 lg:space-y-4 ">
        <h2 className="w-full font-sans-400 text-xl text-left text-teal-700">
          IF Beauty
        </h2>
        <h1 className="font-sans-400 text-3xl lg:text-5xl text-left">
          90 days results
        </h1>
        <p className="text-left w-auto lg:w-[90%] font-sans-200">
        We have developed this Proprietory formula with ingredients that have been scientifically tested with clinical studies conducted by dermatologists. These were the results:
        </p>
        {_props3.v0}
      </div> 
      {_props3.v1}
    </div>, {
  name: "DescriptionPercentage_1",
  portals: ["v0", "v1"]
});
const DescriptionPercentage = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    nextArrow: /*@million jsx-skip*/<JSX_settings_1 v0={<SampleNextArrow />} />,
    prevArrow: /*@million jsx-skip*/<JSX_settings_2 v0={<SamplePrevArrow />} />,
    responsive: [{
      breakpoint: 1025,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true
      }
    }, {
      breakpoint: 769,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 2,
        infinite: true
      }
    }, {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true
      }
    }]
  };
  return /*@million jsx-skip*/<DescriptionPercentage_1 v0={<StatementList />} v1={<VideoWithPreview />} />;
};
export default DescriptionPercentage;